import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable, Subject, throwError, BehaviorSubject} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import { ErrorMsgSettings, commonErrorMsg } from './model/error-messages-settings-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarInfoComponent } from './common-components/components/snack-bar-info/snack-bar-info.component';
import { SnackBarDangerComponent } from './common-components/components/snack-bar-danger/snack-bar-danger.component';
import { VariantScoreAPIResponse } from './common-components/components/variant-score-info-dialog/variant-score-info-model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router, private snackBar: MatSnackBar,) {
  }
  
  public progressLoadFiles = new BehaviorSubject<number>(0);

  public isFileUploaded = new BehaviorSubject<string>('No');
  public variantSearch = new BehaviorSubject<string>(null);


     private downLoadPer = new Subject<any>();
     private subject = new Subject<any>();

     onDownload(data: any) {
          this.downLoadPer.next({ data });
     }

     getDownloadPer(): Observable<any> {
          return this.downLoadPer.asObservable();
      }

     

  status = {'case_init' : ['usergva', 'health'], 
            'confirm': ['confirm-variants', 'reviewLog'], 
            // 'vcf_setting':['get_vcf_settings', 'get_sequencing_platforms', 'get_reference_genomes', 'adapter_marker_settings', 'bqsr_settings', 'haplotype_caller_settings', 'vcf_hard_filtering_settings', 'save_vcf_settings']
          };

  sendCaseSelectionDialog(message: any) {
    this.subject.next(message);
  }

  onCaseSelectionDialog(): Observable<any> {
    return this.subject.asObservable();
  }

  get_url(endpoint){
    let base_url = endpoint.split('/',2)[0];
    const end_url = endpoint.split('/',2)[1];
    if(base_url == 'status'){
      if(this.status.case_init.includes(end_url)){
        base_url = 'status_case_init';
      }
      else if(this.status.confirm.includes(end_url)){
        base_url = 'status_confirm';
      }
      else{
        base_url = 'status_usergva';
      }
    }

    var url = environment[base_url] + endpoint.split('/')[1] + '/';
    return url;
  }

  post(endpoint: string, body?: any, headers?: HttpHeaders): Observable<any> {
    // var url = environment.api_url + endpoint;
    const url = this.get_url(endpoint); //'http://127.0.0.1:8000/apply_filter_panel/';  //  //
    if (!headers) {
      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
    }
    return this.http.post(url, body, {headers: headers}).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  confirmVariants(body: any): Observable<any> {
     const token = JSON.parse(localStorage.getItem('user')).token;
     let headers = new HttpHeaders();
     headers = headers.append('Authorization', `Bearer ${token}`);
     headers = headers.append('Content-Type', 'text/plain');
     var url = environment.status_confirm + 'confirm-variants/';
     return this.http.post(url, body, {headers: headers}).pipe(tap((data) => {
     }, (error) => {
       if (error.status === 401) {
         setTimeout(() => {
           this.router.navigate(['/login']);
         }, 5000);
       }
     }));
   }

  getGeneRegions(endpoint, body: any, headers: HttpHeaders): Observable<any> {
     const url = this.get_url(endpoint);
     return this.http.post(url, body, {headers: headers}).pipe(tap((data) => {
     }, (error) => {
       if (error.status === 401) {
         setTimeout(() => {
           this.router.navigate(['/login']);
         }, 5000);
       }
     }));
   }

   getCNVKGGeneRegions(body: any, headers: HttpHeaders): Observable<any> {
     var url = environment.viewset + 'split_variant_info/';
     return this.http.post(url, body, {headers: headers}).pipe(tap((data) => {
     }, (error) => {
       if (error.status === 401) {
         setTimeout(() => {
           this.router.navigate(['/login']);
         }, 5000);
       }
     }));
   }


  postProband(endpoint: string, body?: any, headers?: HttpHeaders): Observable<any> {
    // var url = environment.api_url + endpoint;
    const url = this.get_url(endpoint);
    if (!headers) {
      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
    }
    return this.http.post(url, body, {headers: headers}).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  put(endpoint: string, body?: any, headers?: HttpHeaders): Observable<any> {
    const url = this.get_url(endpoint);
    if (!headers) {
      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
    }
    return this.http.put(url, body, {headers: headers}).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  get(endpoint): Observable<any> {
    const url = this.get_url(endpoint);
    return this.http.get(url).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  get_without(endpoint: string): Observable<any> {
    // var url = environment.api_url + endpoint;
    const url = this.get_url(endpoint);
    return this.http.get(url).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  get_with_params(endpoint: string, params: any): Observable<any> {
    const url = this.get_url(endpoint);
    // var url = environment.api_url + endpoint;
    return this.http.get(url, params).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  delete(endpoint, params?: any, headers?: HttpHeaders): Observable<any> {
    const url = this.get_url(endpoint);
    if (!headers) {
      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
    }
    let option = {
      headers: headers,
      params: params
    }
    return this.http.delete(url, option).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  get_authorization(endpoint) {
    const url = this.get_url(endpoint);
    const token = JSON.parse(localStorage.getItem('user')).token;
    let header = new HttpHeaders();
    header = header.set('Authorization', `Bearer ${token}`);
    return this.http.get(url, {headers: header}).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  uploadUserImage(imageToUpload: any, headers: HttpHeaders, endpoint){

    const url = this.get_url(endpoint);
    const formData : FormData = new FormData();
    formData.append('file', imageToUpload, imageToUpload.name);
    return this.http.post(url, formData, {headers: headers}).pipe(tap((data)=>{
    },
    (error) =>{
      if(error.status === 401){
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }
    ))
  }

  getUserImage(endpoint, headers: HttpHeaders): Observable<any>{
    const url = this.get_url(endpoint);
    return this.http.get(url, {headers: headers}, ).pipe(tap((data)=>{

    },
    (error)=>{
      if(error.status === 401){
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }
    )
)
  }

  uploadFile(fileToUpload: any, endpoint: string, headers: HttpHeaders, org_id?, user_id?, analysis_type?) {

    let header = new HttpHeaders();
    const url = this.get_url(endpoint);
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    if (analysis_type) {

      formData.append('analysis_type', analysis_type);
    }
    formData.append('org_id', org_id);
    formData.append('user_id', user_id);
    return this.http.post(url, formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
    }).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  postFile(endpoint: any, body, headers: HttpHeaders): Observable<any> {
    // var url = environment.api_url + endpoint;
    const url = this.get_url(endpoint);
    headers = headers.append('content-type', 'application/json');
    return this.http.post(url, body, {
      headers: headers,
      responseType: 'text',
    }).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  get_with_headers_params(
    endpoint: string,
    header: HttpHeaders,
    params: HttpParams
  ): Observable<any> {
    // const url = environment.api_url + endpoint;
    const url = this.get_url(endpoint);
    return this.http.get(url, {headers: header, params: params}).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  get_with_headers(
    endpoint: string,
    header: HttpHeaders,
  ): Observable<any> {
    // const url = environment.api_url + endpoint;
    const url = this.get_url(endpoint);
    return this.http.get(url, {headers: header}).pipe(tap((data) => {
    }, (error) => {
      if (error.status === 401) {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      }
    }));
  }

  getACMGSEFindings (params: HttpParams, headers: HttpHeaders): Observable<any> {
     var url = environment.filters + 'acmg_sf/';
     return this.http.get(url, {headers: headers, params: params}).pipe(tap((data) => {
     }, (error) => {
       
     }));
   }

   getPseudoGeneInfo (payload: any, headers: HttpHeaders): Observable<any> {
     var url = environment.filters + 'get_pseudogene_info/';
     return this.http.post(url, payload, {headers: headers}).pipe(tap((data) => {
     }, (error) => {
       
     }));
   }

   getKGTranscriptInfo (payload: any, headers: HttpHeaders): Observable<any> {
     var url = environment.viewset + 'transcript_info/';
     return this.http.post(url, payload, {headers: headers}).pipe(tap((data) => {
     }, (error) => {
       
     }));
   }

     downloadVariants(body: any, headers: HttpHeaders): Observable<any> {
          var url = environment.filter_panel + 'download_variants/';
          return this.http.post(url, body, {
               headers: headers, responseType: "text"
               }).pipe(tap((data) => {
               }, (error) => {
               }));
     }

     getSuperUserList(header: HttpHeaders): Observable<any> {
          const url = environment.status_confirm+'superuser_list/';
          return this.http.get(url, {headers: header}).pipe(tap((data) => {
          }, (error) => {
            
          }));
     }

     getTopLevelGenePanel(header: HttpHeaders, params?: HttpParams): Observable<any> {
          const url = environment.filter_panel+'retrieve_gene_panels/';
          if(!params) {
               return this.http.get(url, {headers: header, params}).pipe(tap((data) => {
               }, (error) => {
               }));
          } else {
               let option = {
                    headers: header,
                    params: params
               }
               return this.http.get(url, option).pipe(tap((data) => {
               }, (error) => {
               }));
          }
     }

     getGenePanelInfo(header: HttpHeaders, params: HttpParams): Observable<any> {
          const url = environment.filter_panel+'retrieve_gene_panel_info/';
          let option = {
               headers: header,
               params: params
          }
          return this.http.get(url, option).pipe(tap((data) => {
          }, (error) => {
          }));
     }

     getSplitLevelDiseaseInher(header: HttpHeaders, payLoad: any): Observable<any> {
          const url = environment.report+'cnv_updated_report/';
          return this.http.post(url, payLoad, {headers: header}).pipe(tap((data) => {
          }, (error) => {
            
          }));
     }

     downloadURLDiscardedVariants(body: any, headers: HttpHeaders): Observable<any> {
          var url = environment.filter_panel + 'discard_variants/';
          return this.http.post(url, body, {
               headers: headers}).pipe(tap((data) => {
               }, (error) => {
               }));
     }

     downloadVCF_BAM(body: any, headers: HttpHeaders, format: string): Observable<any> {
          if (format === 'BAM' ) {
               var url = environment.filter_panel + 'download_bam/';
          } else if (format === 'BAI' ) {
               var url = environment.filter_panel + 'download_bai/';
          } else if (format === 'VCF' ) {
               var url = environment.filter_panel + 'download_vcf/';
          }
          return this.http.post(url, body, {
               headers: headers}).pipe(tap((data) => {
               }, (error) => {
               }));
     }

     downloadVCFAPI(body: any, headers: HttpHeaders): Observable<any> {
          var url = environment.filter_panel + 'download_vcf/';
          return this.http.post(url, body, {
               headers: headers, responseType: "blob", observe: 'events',reportProgress: true,
               }).pipe(tap((data) => {
               }, (error) => {
               }));
     }

     downloadVCF(body: any, headers: HttpHeaders) {
          this.snackBar.openFromComponent(SnackBarInfoComponent, {
               data: { msg: 'Download in progress' },
               panelClass: 'snackBarInfo',
               duration: 10000
          });
          this.downloadVCFAPI(
               body,
               headers
          )
          .pipe(
               tap(event => {
                    if (event.type === HttpEventType.Sent) {
                         
                    }
                    if (event.type === HttpEventType.ResponseHeader) {
                         
                    }
                    if (event.type === HttpEventType.DownloadProgress) {
                         let downloadPer = Math.round(100 * event.loaded / event.total);
                         this.onDownload({
                              status: 'In progress',
                              percentage: downloadPer,
                              caseId: body.case_id
                         })
                    }
                    if (event.type === HttpEventType.Response) {
                         const contentDispositionHeader: string = event.headers.get('Content-Disposition');
                         const parts: string[] = contentDispositionHeader.split(';');
                         const filename = parts[1].split('=')[1];
                         const blob = new Blob([event.body], {
                              type: 'application/zip'
                         });
                         const url = window.URL.createObjectURL(blob);
                         var anchor = document.createElement("a");
                         if(filename) {
                              anchor.download = filename;
                         } else {
                              anchor.download = body.case_id+'.csv.gz';
                         }
                         anchor.href = url;
                         anchor.click();
                         this.onDownload({
                              status: 'Completed',
                              percentage: '100',
                              caseId: body.case_id
                         })
                    }
               }),
               catchError((error: HttpErrorResponse) => {
                    if (error instanceof HttpErrorResponse) {
                         let errorMessage = 'Something went wrong while processing your request. Try again in a few minutes or contact System Support';
                         error.error.text().then (
                              (msg) => {
                                   errorMessage = JSON.parse(msg).user_specific_message;
                                   this.snackBar.openFromComponent(SnackBarDangerComponent, {
                                        data: { msg: errorMessage},
                                        panelClass: 'snackBarDanger',
                                        duration: ErrorMsgSettings.duration
                                   });
                              }
                         )
                         return throwError(errorMessage);
                    } else {
                         this.snackBar.openFromComponent(SnackBarDangerComponent, {
                              data: { msg: 'An unexpected error occurred:'},
                              panelClass: 'snackBarDanger',
                              duration: ErrorMsgSettings.duration
                         });
                    }
                    return throwError(error);
               })
          )
          .subscribe(
               () => {
                    
               },
               (error) => {
                    this.snackBar.openFromComponent(SnackBarDangerComponent, {
                         data: { msg: 'An unexpected error occurred:'},
                         panelClass: 'snackBarDanger',
                         duration: ErrorMsgSettings.duration
                    });
               }
          );
     }

     consolidatedDiseases(params: HttpParams, headers: HttpHeaders): Observable<any> {
          var url = environment.filter_panel + 'consolidated_diseases/';
          return this.http.get(url, {headers: headers, params: params}).pipe(tap((data) => {
          }, (error) => {
            if (error.status === 401) {
              setTimeout(() => {
                this.router.navigate(['/login']);
              }, 5000);
            }
          }));
     }
  
     deleteCase(header: HttpHeaders, params: HttpParams, body) {
          const url = environment.status_usergva + 'delete_case/';
          let option = {
               headers: header,
               params: params
          }
          return this.http.delete(url, option).pipe(tap((data) => {
          }, (error) => {
          }));
     }

     getFrequencyOfVariants(header): Observable<any>{
      // const url = "https://filter-panel.dev1.gva.semgenome.com/get_frequency_of_variants/";
      const url = environment.filter_panel + "get_frequency_of_variants/"
      return this.http.get(url, {headers: header}).pipe(tap((data) => {
      }, (error) => {
        if (error.status === 401) {
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 5000);
        }
      }));
     }

     getPhenoTypesTree(header: HttpHeaders, body: any): Observable<any> {
          const url = environment.filter_panel + 'phenotype_tree/'
          return this.http.post<any>(url, body, {headers: header}).pipe(
               map((res) => res),
          );
     }

     saveSingleVariant(headers: HttpHeaders, payload: any): Observable<any> {
          var url = environment.single + 'single_variant_save/';
          return this.http.post(url, payload, {headers: headers}).pipe(tap((data) => {
          }, (error) => {
               
          }));
     }

     getZygosity(headers: HttpHeaders): Observable<any> {
          const url = environment.single + 'zygosity/';
          return this.http.get(url, {headers: headers}, ).pipe(tap((data)  => {
          }, (error) => {
          }));
     }

     variantInfo(body?: any, headers?: HttpHeaders): Observable<any> {
          const url = environment.viewset + 'variant_info/'
          if (!headers) {
            var headers = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
          }
          return this.http.post(url, body, {headers: headers}).pipe(tap((data) => {
          }, (error) => {
            if (error.status === 401) {
              setTimeout(() => {
                this.router.navigate(['/login']);
              }, 5000);
            }
          }));
     }

     getKGMatchedPhenotypes(body: any, headers: HttpHeaders): Observable<any> {
          const url = environment.viewset + 'matched_phenotype/';
          return this.http.post(url, body, {headers: headers}).pipe(tap((data) => {
          }, (error) => {
            
          }));
     }

     transactionCheck(headers: HttpHeaders, params:HttpParams ): Observable<{allowed: boolean}> {
          const url = environment.status_case_init + 'allow-transaction/';
          return this.http.get<{allowed: boolean}>(url, {headers: headers, params}).pipe(tap((data: {allowed: boolean}) => {
          }, (error) => {
            
          }));
     }

     getVariantScore(payLoad: any): Observable<VariantScoreAPIResponse[]> {
          const token = JSON.parse(localStorage.getItem('user')).token;
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', `Bearer ${token}`);
          var url = environment.filter_panel + 'variant_score/';
          return this.http.post(url, payLoad, {headers: headers}).pipe(tap((data: VariantScoreAPIResponse[]) => {
          }, (error) => {
          }));
     }
}

